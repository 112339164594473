import { useMemo } from 'preact/hooks'

import { usePageContext } from './page-context'

export const useRoute = (path) => {
  const pageContext = usePageContext()

  return useMemo(() => {
    const currentRoute = cleanPath(pageContext.urlPathname)
    const matches = path && currentRoute === cleanPath(path)

    return {
      matches,
      currentRoute,
    }
  }, [path, pageContext.urlPathname])
}

function cleanPath(path) {
  if (!path) {
    return
  }

  return path.replace(/\?.+$/, '').replace(/\/?$/, '')
}
