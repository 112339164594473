import { h } from 'preact'
import classNames from 'clsx'

import { PATHS, TITLES } from '../../../config'
import SiteLogo from '../../atom/SiteLogo'
import LinkWrapper from '../../atom/link-wrapper'

import styles from './index.module.scss'
import { SiteHeaderNavigation } from './header-navigation'
import SiteHeaderImage from './header-image'

function SiteHeader({ className, pageProps }) {
  return (
    <header className={classNames(className, styles['o-site-header'])}>
      <SiteHeaderImage
        pageProps={pageProps}
        className={styles['o-site-header--image']}
      />

      <div className={styles['o-site-header__logo']}>
        <h1 className={styles['o-site-header__site-name']}>
          <LinkWrapper href={PATHS.HOME} title={TITLES.SITE} internal>
            <SiteLogo title={TITLES.SITE} />
          </LinkWrapper>
        </h1>
      </div>

      <SiteHeaderNavigation />
    </header>
  )
}

export default SiteHeader
