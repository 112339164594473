import { h } from 'preact'

const NextLinkWrapper = (props) => {
  const {
    href,
    as,
    internal,
    linkProps,
    children,
    ...rest
  } = props

  if (typeof href === 'string' && !linkProps && !internal) {
    return <a href={href || ''} {...rest}>{children}</a>
  }

  const asValue = as || href && href.as
  const hrefValue = href && href.href || href

  return (
    <a {...rest} href={asValue || hrefValue}>
      {children}
    </a>
  )
}

export default NextLinkWrapper
