import { h } from 'preact'
import { Search, X } from 'react-feather'

import { AsSiteNavigationEntry } from '../../molecule/SiteNavigation'
import { TITLES } from '../../../config'

const ToggleSearchButton = AsSiteNavigationEntry(function ToggleSearch(props) {
  const {
    open,
    ...rest
  } = props

  const IconElement = open ? X : Search

  return (
    <button
      type="button"
      title={TITLES.TOGGLE_SEARCH}
      aria-label={TITLES.TOGGLE_SEARCH}
      {...rest}
    >
      <IconElement size={20} />
    </button>
  )
})

export default ToggleSearchButton
