import { h } from 'preact'
import { Search } from 'react-feather'
import classNames from 'clsx'
import { useLayoutEffect, useRef } from 'preact/hooks'

import { BASE_WORDPRESS_URL, TITLES } from '../../../config'

import styles from './index.module.scss'

function SearchBox({ className, autoFocus, ...props }) {

  const inputRef = useRef()

  useLayoutEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  return (
    <form
      method="GET"
      action="https://www.google.com/search"
      {...props}
      className={buildClassNames({ className })}
    >
      <input type="hidden" name="q" value={`site:${BASE_WORDPRESS_URL}`} />
      <input type="search" name="q" className={styles['a-search-form__input']} ref={inputRef} />

      <button
        type="submit"
        title={TITLES.SEARCH}
        aria-label={TITLES.SEARCH}
        className={styles['a-search-form__submit']}
      >
        <Search size={18} />
      </button>
    </form>
  )
}

function buildClassNames({ className }) {
  return classNames(
    className,
    styles['a-search-form'],
  )
}

export default SearchBox
