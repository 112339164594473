const BASE_IMAGES_URL = '/public'

const HERO_IMAGES = Object.keys(
  import.meta.glob(
    [
      '/public/headers/*.avif',
      '/public/headers/*.webp',
      '/public/headers/*.png',
      '/public/headers/*.jpg',
      '/public/headers/*.jpeg',
    ],
    { as: 'url' },
  )
).map(entry => entry.substring(BASE_IMAGES_URL.length))

export default HERO_IMAGES
