import { h } from 'preact'
import { useContext, useMemo } from 'preact/hooks'
import get from 'lodash-es/get'

import HeroImage from '../../../atom/HeroImage'
import { isStaticURL } from '../../../atom/nbb-smart-img'
import { BASE_STATIC_URL, ENABLE_TRANSFORMED_IMAGES } from '../../../../config'

import { Context } from './provider'

const MIN_IMAGE_WIDTH = 580
const MIN_IMAGE_HEIGHT = 600

function SiteHeaderImage(props) {
  const { className, pageProps } = props

  const ctx = useContext(Context)

  const featuredImage = get(pageProps, 'data.post.featuredImage')
  const { mediaDetails, mediaType, mediaItemUrl } = featuredImage || {}

  const hasTeaserImage = useMemo(
    () => mediaType === 'image' && mediaItemUrl && mediaDetails &&
      mediaDetails.width > MIN_IMAGE_WIDTH &&
      mediaDetails.height > MIN_IMAGE_HEIGHT &&
      isStaticURL(mediaItemUrl),
    [mediaType, mediaItemUrl, mediaDetails],
  )

  const fallbackSrc = useMemo(
    () => pickRandomImage(ctx),
    [ctx],
  )

  const src = hasTeaserImage ? mediaItemUrl : fallbackSrc

  return (
    <HeroImage
      src={src}
      className={className}
    />
  )
}

function pickRandomImage(images = [], filter = []) {
  const candidates = images.filter(i => filter.indexOf(i) === -1)
  const selected = Math.round(getRandomInRange(0, candidates.length - 1))

  if (ENABLE_TRANSFORMED_IMAGES) {
    return BASE_STATIC_URL + candidates[selected]
  }

  return candidates[selected]
}

function getRandomInRange(min, max) {
  return Math.random() * (max - min) + min
}

export default SiteHeaderImage
