import { h } from 'preact'
import classNames from 'clsx'

import SmartImg from '../nbb-smart-img'

import styles from './index.module.scss'

const SIZES_ATTR = '100vw'

// TODO: fade between two images
const HeroImage = (props) => {
  const { className, alt = '', ...rest } = props

  // TODO: fade new image in
  return (
    <SmartImg
      alt={alt}
      minSize={300}
      sizes={SIZES_ATTR}
      placeholder
      preview
      className={buildClassNames({ className })}
      {...rest}
    />
  )
}

function buildClassNames({ className }) {
  return classNames(
    className,
    styles['a-hero-image'],
  )
}

export default HeroImage
