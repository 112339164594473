import { h } from 'preact'

// eslint-disable-next-line import/no-unresolved
import Logo from './assets/logo.svg?raw'
import styles from './index.module.scss'

const SiteLogo = ({ title }) => (
  <span
    className={styles['a-site-logo']}
    dangerouslySetInnerHTML={{
      __html: Logo.replace(/<svg/, `<svg role="img" alt="${title}" `),
    }}
  />
)

export default SiteLogo
