import { h } from 'preact'
import classNames from 'clsx'

import SiteContent from '../../molecule/SiteContent'
import SiteFooter from '../../organism/SiteFooter'
import SiteHeader from '../../organism/SiteHeader'

import styles from './index.module.scss'

const HorizontalThirdsTemplate = ({ children, pageProps, footer, className }) => (
  <div className={classNames(className, styles['t-horizontal-thirds'])} >
    <SiteHeader
      className={styles['t-horizontal-thirds__header']}
      pageProps={pageProps}
    />

    <main className={styles['t-horizontal-thirds__content']}>
      <SiteContent>
        {children}
      </SiteContent>
    </main>

    <SiteFooter className={styles['t-horizontal-thirds__footer']}>
      {footer}
    </SiteFooter>
  </div>
)

export default HorizontalThirdsTemplate
