import { h } from 'preact'
import { useMemo } from 'preact/hooks'
import isBoolean from 'lodash-es/isBoolean'

import NextLinkWrapper from './components/router/link'
import { LinkProvider } from './components/atom/link-wrapper'
import { SmartImgSettingsProvider } from './components/atom/nbb-smart-img'
import { PATHS } from './config'
import HorizontalThirdsTemplate from './components/template/HorizontalThirds'
import HorizontalHalvesTemplate from './components/template/HorizontalHalves'
import { SiteHeaderImagesProvider } from './components/organism/SiteHeader/header-image/provider'
import CookieDisclaimer from './components/organism/cookie-disclaimer'
import CFBeacon from './components/atom/cf-beacon'
import HERO_IMAGES from './components/organism/SiteHeader/header-image/crawler'
import { PageContextProvider } from './components/router/page-context'

import '@fontsource/roboto-condensed'
import 'normalize.css/normalize.css'
import '../css/00_base/index.scss'

export function BlogApp ({ pageContext }) {
  const { Page, pageProps, errorWhileRendering } = pageContext

  const cookiePolicyLink = useMemo(() => PATHS.COOKIE_POLICY, [])

  const [Template, FooterComponent] = useMemo(
    () => {
      const FooterComponent = getFooterComponent(Page)

      const Template = FooterComponent !== false
        ? HorizontalThirdsTemplate
        : HorizontalHalvesTemplate

      return [
        Template,
        isBoolean(FooterComponent)
          ? undefined
          : FooterComponent,
      ]
    },
    [Page],
  )

  return (
    <PageContextProvider pageContext={pageContext}>
      <SmartImgSettingsProvider>
        <LinkProvider value={NextLinkWrapper}>
          <SiteHeaderImagesProvider images={HERO_IMAGES}>
            <Template
              className="main-content"
              footer={FooterComponent && <FooterComponent {...pageProps} />}
              pageProps={pageProps}
            >
              <Page error={errorWhileRendering} {...pageProps} />
            </Template>

            <CookieDisclaimer link={cookiePolicyLink} />
          </SiteHeaderImagesProvider>
        </LinkProvider>

        <CFBeacon token={import.meta.env?.VITE_CF_TOKEN} />
      </SmartImgSettingsProvider>
    </PageContextProvider>
  )
}

function getFooterComponent(Page) {
  const footerDisabled = Page.Footer === false
  const footerEnabled = Page.Footer === true
  const FooterComponent = Page.Footer

  if (footerDisabled) {
    return false
  }

  if (footerEnabled || !FooterComponent) {
    return true
  }

  return FooterComponent
}

