import { h } from 'preact'
import classNames from 'clsx'

import SiteContent from '../../molecule/SiteContent'
import SiteHeader from '../../organism/SiteHeader'

import styles from './index.module.scss'

const HorizontalHalvesTemplate = ({ children, pageProps, className }) => (
  <div className={classNames(className, styles['t-horizontal-halves'])}>
    <SiteHeader
      className={styles['t-horizontal-halves__header']}
      pageProps={pageProps}
    />

    <SiteContent Element="main" className={styles['t-horizontal-halves__content']}>
      {children}
    </SiteContent>
  </div>
)

export default HorizontalHalvesTemplate
