import { h } from 'preact'
import { useCallback, useState } from 'preact/hooks'

import SiteNavigation from '../../molecule/SiteNavigation'
import SiteMenu from '../../molecule/site-menu'
import { MENU_ENTRIES } from '../../../config'
import SearchBox from '../../atom/SearchForm'
import { useMounted } from '../../../hooks'

import styles from './index.module.scss'
import ToggleSearchButton from './search-button'

export function SiteHeaderNavigation() {
  const [searchOpen, setSearchOpen] = useState(false)

  const toggleSearch = useCallback(
    () => setSearchOpen(open => !open),
    [setSearchOpen],
  )

  // FIXME: implement a css only way to toggle the search form so javascript is unnecessary
  const isMounted = useMounted()

  return (
    <SiteNavigation className={styles['o-site-header__navigation']}>
      <SiteMenu entries={MENU_ENTRIES} className={styles['o-site-header__menu']} />

      <ToggleSearchButton
        onClick={toggleSearch}
        open={searchOpen}
        disabled={!isMounted}
        className={styles['o-site-header__search-button']}
      />

      <div
        className={styles['o-site-header__search-box']}
        style={searchOpen ? undefined : { display: 'none' }}
      >
        {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
        <SearchBox autoFocus />
      </div>
    </SiteNavigation>
  )
}
