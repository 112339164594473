import { h } from 'preact'
import classNames from 'clsx'

import { getDisplayName } from '../../utils'

import styles from './index.module.scss'

const SiteNavigation = ({ className, children }) => (
  <nav role="navigation" className={classNames(className, styles['m-site-navigation'])}>
    {children}
  </nav>
)

export function AsSiteNavigationEntry(Component, displayName) {
  const WrappedComponent = ({ className, ...props }) => (
    <Component
      {...props}
      className={buildEntryClassName({ className })}
    />
  )

  WrappedComponent.displayName = displayName || `AsSiteNavigationEntry(${getDisplayName(Component)})`

  return WrappedComponent
}

function buildEntryClassName({ className }) {
  return classNames(
    className,
    styles['m-site-navigation__entry'],
  )
}

export default SiteNavigation
